import { ArticleItem } from '@src/shared/components/learning-center/lc-article-list/lc-article-list.interface';
import { LCResourceItem } from '../lc-category.interface';

interface SectionTiles {
  bannerArticle: LCResourceItem | null;
  highLightArticles: LCResourceItem[] | [];
  articleList: ArticleItem[] | [];
  noImages: boolean;
}

export const sectionTiles = (
  articles: LCResourceItem[],
  suppressImages: boolean
): SectionTiles => {
  const highLightCount =
    articles[1]?.featured_image?.image &&
    articles[2]?.featured_image?.image &&
    !suppressImages
      ? 3
      : 5;
  const data: SectionTiles = articles.reduce(
    (memo: any, current: LCResourceItem, idx: number) => {
      if (idx === 0) {
        return {
          ...memo,
          bannerArticle: current,
        };
      }
      if (idx < highLightCount) {
        return {
          ...memo,
          highLightArticles: [...memo?.highLightArticles, current],
        };
      }
      return {
        ...memo,
        articleList: [...memo?.articleList, mapperArticle(current)],
      };
    },
    {
      bannerArticle: null,
      highLightArticles: [],
      articleList: [],
      noImages: true,
    }
  );
  return data;
};

const mapperArticle = (article: LCResourceItem) => ({
  uid: article?.uid,
  headline: article?.headline,
  headlineLink: article?.page_url,
  date: article?.publication_date,
  image: article?.featured_image?.image,
  topic: article?.topics?.map((topic) => topic?.display_name).join(', '),
  logoCard: {
    image: article?.logo_card?.logo,
    background: article?.logo_card?.card_color?.colors,
  },
});
