import {
  LCCategoryData,
  LCCategoryDataProps,
} from '@cms-components/learning-center/lc-category/lc-category.interface';
import { menuNavMapper } from '@src/shared/mappers/menuNav.mapper';

export const lcCategoryMapper = ({
  pageContext,
}: LCCategoryDataProps): LCCategoryData => {
  const {
    resources,
    topics: LCTopics,
    sections,
    rootPage,
    navigation_name,
    url_segment: urlSegment,
    site_configuration,
    allArticles,
    suppress_images,
    latestArticlesTitle,
  } = pageContext || {};
  const maxLastestArticles = 3;

  const { menu, topics } = menuNavMapper(
    LCTopics,
    sections,
    rootPage,
    navigation_name
  );

  return {
    menu,
    navigation_name,
    topics,
    resources,
    featureArticleFallbackImage:
      site_configuration?.featured_article_fallback_image,
    urlSegment,
    suppressImages: suppress_images,
    latestArticles: {
      variant: 'light',
      title: latestArticlesTitle,
      articleList: allArticles
        .slice(0, maxLastestArticles)
        .map(
          ({
            featured_image,
            logo_card,
            topics,
            headline,
            path,
            teaser_text,
            lc_content_type,
            path_category,
            uid,
          }) => {
            const category = lc_content_type[0];

            return {
              image: featured_image?.image,
              logoCard: {
                image: logo_card?.logo,
                background: logo_card?.card_color?.colors,
              },
              fallbackImage:
                site_configuration?.featured_article_fallback_image,
              topics: topics?.map(({ display_name }) => display_name),
              headline,
              headlineLink: path,
              teaser: teaser_text,
              category: category?.navigation_name,
              categoryLink: path_category || '',
              uid,
              topicColor: '',
              teaserColor: '',
              categoryLinkColor: '',
            };
          }
        ),
    },
  };
};
