import styled from '@emotion/styled';
import { mq_min } from '@styles/breakpoints';
import {
  color_blue,
  color_darkest_grey,
  color_dark_grey,
  color_lightest_grey,
  color_white,
  global_colors,
} from '@styles/colors';
import { getStyleSizes } from '@styles/styles.utils';
import { SuppressImagesProp, VariantProp } from './lc-article-list.interface';
import { global_typography, noTo_font } from '@styles/typography';

export const themes = {
  light: {
    containerPanelBackground: color_white,
  },
  mid: {
    containerPanelBackground: color_lightest_grey,
  },
};

export const Container = styled.div<VariantProp>`
  padding: ${getStyleSizes([60, 0])};
  background: ${({ variant }) => themes[variant]?.containerPanelBackground};
`;

export const ContainerItem = styled.div<SuppressImagesProp>`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${getStyleSizes(25)};
  ${({ suppressImages }) => {
    if (suppressImages) {
      return `
        ${mq_min('sm')} {
          grid-gap: ${getStyleSizes(29)};
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
      `;
    } else {
      return `
        ${mq_min('lg')} {
          grid-gap: ${getStyleSizes(29)};
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
      `;
    }
  }}
`;

export const Item = styled.div<SuppressImagesProp>`
  width: 100%;
  min-height: ${getStyleSizes(70)};
  display: flex;

  ${mq_min('lg')} {
    &:nth-of-type(n + 5) {
      margin-top: ${getStyleSizes(40)};
    }
    display: inline-block;
    width: ${getStyleSizes(235)};
    min-height: ${({ suppressImages }) =>
      suppressImages ? `${getStyleSizes(100)}` : `${getStyleSizes(353)}`};
  }
`;

export const ItemDetail = styled.div`
  flex: 1;
`;

export const ItemImage = styled.div<{
  backgroundColor: string;
}>`
  border-radius: 4px;
  object-fit: cover;
  margin-bottom: ${getStyleSizes(20)};
  width: ${getStyleSizes(70)};
  height: ${getStyleSizes(70)};
  margin-right: ${getStyleSizes(25)};
  ${({ backgroundColor }) =>
    backgroundColor &&
    `
    background: ${backgroundColor};
  `}

  .gatsby-image-wrapper {
    &,
    & div {
      width: 100%;
      height: 100%;
    }
  }
  img {
    width: 100%;
  }
  ${mq_min('lg')} {
    margin-right: 0;
    width: ${getStyleSizes(235)};
    height: ${getStyleSizes(235)};
  }
`;

export const ItemTopic = styled.p`
  font-size: ${getStyleSizes(12)};
  font-weight: 600;
  color: ${color_blue};
  margin-bottom: ${getStyleSizes(8)};
  text-transform: uppercase;
`;

export const ItemHeadline = styled.h3`
  font-size: ${getStyleSizes(16)};
  font-weight: 600;
  color: ${color_darkest_grey};
  line-height: ${getStyleSizes(21.6)};

  ${mq_min('lg')} {
    font-size: ${getStyleSizes(18)};
  }
`;

export const ItemTeaser = styled.p`
  font-family: ${noTo_font};
  font-size: ${getStyleSizes(global_typography.copy.base)};
  font-weight: 200;
  margin-top: ${getStyleSizes(20)};
  color: ${color_dark_grey};
  white-space: initial;

  ${mq_min('lg')} {
    margin-top: ${getStyleSizes(36)};
  }
`;

export const ItemDate = styled.time`
  font-size: ${getStyleSizes(12)};
  font-weight: 300;
  color: ${global_colors.link.dark};
  margin-top: ${getStyleSizes(6)};
  padding-bottom: ${getStyleSizes(6)};
  font-family: ${noTo_font};
`;

export const ButtonContainer = styled.div`
  text-align: center;
  width: 100%;
  margin-top: ${getStyleSizes(40)};
`;

export const TextResults = styled.div`
  font-size: ${getStyleSizes(13)};
  font-weight: 300;
  color: ${color_dark_grey};
  margin-top: ${getStyleSizes(6)};
  padding-bottom: ${getStyleSizes(6)};
  font-family: ${noTo_font};
`;
