import React from 'react';
import { LCResourceItem } from '@cms-components/learning-center/lc-category/lc-category.interface';
import { LCArticleTile } from '@shared/components/learning-center/lc-article-tile';
import { HighlightsContainer } from '@cms-components/learning-center/lc-category/components/highlights/highlights.styles';

export const Highlights = ({
  articles,
  urlSegment,
  suppressImages,
}: {
  articles: LCResourceItem[];
  urlSegment: string;
  suppressImages: boolean;
}) => {
  return (
    <HighlightsContainer suppressImages={suppressImages}>
      {articles?.map((article, index) => {
        const { topics } = article || {};
        const tileTopics = topics?.map((topic) => topic.display_name) || [];

        return (
          <LCArticleTile
            {...article}
            headlineLink={article.page_url || ''}
            teaser={article.teaser_text || ''}
            headline={article.headline || ''}
            image={article.featured_image.image}
            topics={tileTopics}
            publicationDate={article.publication_date || ''}
            key={index}
            urlSegment={urlSegment}
            logoCard={{
              image: article.logo_card.logo,
              background: article.logo_card.card_color.colors,
            }}
            suppressImages={suppressImages}
          />
        );
      })}
    </HighlightsContainer>
  );
};
