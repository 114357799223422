import styled from '@emotion/styled';
import { mq_min } from '@styles/breakpoints';
import { getStyleSizes } from '@styles/styles.utils';
import { HighlightsContainerProps } from '@cms-components/learning-center/lc-category/components/highlights/highlights.interface';

export const HighlightsContainer = styled.div<HighlightsContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${getStyleSizes(60)};
  ${({ suppressImages }) => {
    if (suppressImages) {
      return `
      ${mq_min('sm')} {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: ${getStyleSizes(80)};
        margin-bottom: ${getStyleSizes(60)};
      }
      `;
    }
  }}

  ${mq_min('lg')} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: ${getStyleSizes(80)};
    margin-bottom: ${getStyleSizes(60)};
  }

  & > * {
    width: 100%;
    max-width: ${getStyleSizes(580)};
    &:not(:last-of-type) {
      ${mq_min('sm')} {
        margin-right: ${({ suppressImages }) =>
          suppressImages ? `${getStyleSizes(20)}` : 0};
      }
      ${mq_min('lg')} {
        margin-right: 0;
      }
    }
  }

  & > * {
    margin-bottom: ${getStyleSizes(24)};
    ${mq_min('lg')} {
      margin-bottom: 0;
    }
  }
`;
