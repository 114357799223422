import CMSComponentWrapper from '@shared/components/component-wrapper';
import {
  IconLink,
  ImageComponent,
  PrimaryButton,
} from '@src/shared/components';
import React from 'react';
import { LCArticleListProps } from './lc-article-list.interface';
import {
  ButtonContainer,
  Container,
  ContainerItem,
  Item,
  ItemDetail,
  ItemDate,
  ItemHeadline,
  ItemImage,
  ItemTopic,
  TextResults,
} from './lc-article-list.styles';
import { default_breakpoints } from '@styles/breakpoints';

export const LCArticleList = ({
  variant = 'light',
  articleList,
  urlSegment,
  suppressImages,
}: LCArticleListProps) => {
  const page = 8;
  const pageInitial = 1;
  const totalItems = articleList.length;

  const [itemsShowing, setItemsShowing] = React.useState(page * pageInitial);
  const [pageCurrent, setPageCurrent] = React.useState(pageInitial);

  React.useEffect(() => {
    setItemsShowing(page * pageCurrent);
  }, [pageCurrent]);

  if (!totalItems) return <></>;

  return (
    <Container variant={variant} data-testid="lc-article-list__container">
      <CMSComponentWrapper maxWidth={default_breakpoints.cw}>
        <ContainerItem suppressImages={suppressImages}>
          {[...articleList.slice(0, itemsShowing)]?.map(
            ({ image, topic, headline, headlineLink, date, logoCard, uid }, key) => {
              const imageSelected = image.image ? image : logoCard?.image;
              const isFallbackImage = !image.image && logoCard?.image?.image;

              return (
                <Item
                  id={uid}
                  key={key}
                  suppressImages={
                    suppressImages || Boolean(!imageSelected?.image)
                  }
                >
                  {!!imageSelected?.image?.url && !suppressImages && (
                    <ItemImage
                      backgroundColor={
                        isFallbackImage ? logoCard?.background || '' : ''
                      }
                    >
                      <ImageComponent
                        image={imageSelected.image.gatsbyImageData}
                        alt_text={imageSelected.image_alt_text}
                      />
                    </ItemImage>
                  )}
                  <ItemDetail>
                    {!!topic && <ItemTopic>{topic}</ItemTopic>}

                    <ItemHeadline>
                      <IconLink
                        isBold
                        label={headline}
                        linkUrl={headlineLink}
                      />
                    </ItemHeadline>
                    {urlSegment?.indexOf('blogs') !== -1 && date && (
                      <ItemDate>{date}</ItemDate>
                    )}
                  </ItemDetail>
                </Item>
              );
            }
          )}
        </ContainerItem>
        {itemsShowing < totalItems && (
          <ButtonContainer>
            <PrimaryButton
              ariaLabel="see more"
              onClick={() => setPageCurrent(pageCurrent + 1)}
              label="Load More"
              size="large"
              variant="contained"
              icon="text"
            />
            <TextResults>{totalItems - itemsShowing} More Results</TextResults>
          </ButtonContainer>
        )}
      </CMSComponentWrapper>
    </Container>
  );
};
