import { LandingPageBanner } from '@shared/components/landing-page-banner';
import { Highlights } from '@cms-components/learning-center/lc-category/components/highlights';
import {
  LCCategoryData,
  LCCategoryDataProps,
} from '@cms-components/learning-center/lc-category/lc-category.interface';
import {
  Header,
  Heading,
  LCCategoryContainer,
} from '@cms-components/learning-center/lc-category/lc-category.styles';
import { lcCategoryMapper } from '@cms-components/learning-center/lc-category/mappers/lc-category.mapper';
import CMSComponentWrapper from '@shared/components/component-wrapper';
import { TopicsFilterProvider } from '@shared/components/learning-center/context/topics-filter.context';
import { LCMenu } from '@shared/components/learning-center/lc-menu/lc-menu';
import { LCArticleList } from '@src/shared/components/learning-center/lc-article-list';
import { LCMainArticleList } from '@shared/components';

import React from 'react';
import { useSectionTiles } from './hooks/useSectionTiles';

export const LCCategory = (props: LCCategoryData) => {
  const {
    resources,
    topics,
    menu,
    navigation_name,
    urlSegment,
    featureArticleFallbackImage,
    latestArticles,
    suppressImages,
  } = props || {};
  const { bannerArticle, highLightArticles, articleList } = useSectionTiles(
    resources,
    suppressImages
  );
  return (
    <LCCategoryContainer>
      <LCMenu
        filters={topics}
        menu={menu}
        logoText={'Learning Center'}
        backgroundColorMenu={'dark'}
        backgroundColorFilter={'light'}
      />
      <CMSComponentWrapper maxWidth={1240}>
        <Header>
          <Heading>{navigation_name}</Heading>
          {bannerArticle && (
            <LandingPageBanner
              firstArticle={bannerArticle}
              fallbackImage={featureArticleFallbackImage}
              urlSegment={urlSegment}
            />
          )}
          <Highlights
            articles={highLightArticles}
            urlSegment={urlSegment}
            suppressImages={suppressImages}
          />
        </Header>
      </CMSComponentWrapper>
      <LCArticleList
        variant={'mid'}
        articleList={articleList}
        urlSegment={urlSegment}
        suppressImages={suppressImages}
      />
      <LCMainArticleList {...latestArticles} />
    </LCCategoryContainer>
  );
};

export const MappedLCCategory = ({ pageContext }: LCCategoryDataProps) => {
  const data = lcCategoryMapper({ pageContext });
  const { topics } = data || {};
  return (
    <TopicsFilterProvider topics={topics}>
      <LCCategory {...data} />
    </TopicsFilterProvider>
  );
};
