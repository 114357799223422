import { useTopicsFilter } from '@src/shared/components/learning-center/context/topics-filter.context';
import { useEffect, useState } from 'react';
import { LCResourceItem } from '../lc-category.interface';
import { sectionTiles } from '../mappers/lc-section-tiles.mapper';

export const useSectionTiles = (
  resources: LCResourceItem[] = [],
  suppressImages: boolean
) => {
  const { activeItems } = useTopicsFilter();

  const [currentResources, setCurrentResources] =
    useState<LCResourceItem[]>(resources);

  useEffect(() => {
    const activeItemsIds = activeItems
      .filter((item) => item?.active)
      .map((item) => item?.id);

    if (activeItemsIds.length) {
      const filteredArticles = resources?.filter((resource) => {
        const findTopic = resource?.topics?.find(
          (topic) => topic?.id && activeItemsIds?.includes(topic?.id)
        );
        return !!findTopic;
      });
      return setCurrentResources(filteredArticles);
    }
    return setCurrentResources(resources);
  }, [activeItems]);
  return { ...sectionTiles(currentResources, suppressImages) };
};
